
import { BaseProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import Vue from 'vue';

export default Vue.extend({
  name: 'recommendedProduct',

  useInjectable: [Models.BaseProduct],

  props: {
    product: {
      type: Object,
    },
  },
  data: (): {} => ({}),
  methods: {
    increaseToRecommendations(): void {
      this.$store.dispatch('newProposal/increaseToRecommendations', this.product);
    },
    productIcon(categoryName): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(categoryName).icon;
    },
  },
  computed: {
    increaseDays(): number {
      return this.$store.state['newProposal'].newProposal?.increasedRecommendations?.newDays || 0;
    },
    currentDays(): number {
      return this.$store.getters['newProposal/numberOfDaysInProposal'];
    },
    increaseBudget(): number {
      return this.$store.state['newProposal'].newProposal?.increasedRecommendations?.newBudget || 0;
    },
    currentBudget(): number {
      return this.$store.state['newProposal'].newProposal.budget;
    },
    increaseTextPrompt(): string {
      let text = '';
      const increaseBudgetFormatted = this.$options.filters.formatPrice(this.increaseBudget);
      if (
        this.increaseBudget &&
        this.increaseDays &&
        this.increaseBudget > this.currentBudget &&
        this.increaseDays > this.currentDays
      ) {
        text = `Increase budget to ${increaseBudgetFormatted} and days to ${this.increaseDays} to accommodate this product`;
      } else if (this.increaseBudget && this.increaseBudget > this.currentBudget) {
        text = `Increase budget to ${increaseBudgetFormatted} to accommodate this product`;
      } else if (this.increaseDays && this.increaseDays > this.currentDays) {
        text = `Increase days to ${this.increaseDays} to accommodate this product`;
      } else {
        text = 'This product can be added without changing budget/days.';
      }
      return text;
    },
    buttonText(): string {
      let text = '';
      if (
        this.increaseBudget &&
        this.increaseDays &&
        this.increaseBudget > this.currentBudget &&
        this.increaseDays > this.currentDays
      ) {
        text = 'Increase Values';
      } else if (this.increaseBudget && this.increaseBudget > this.currentBudget) {
        text = 'Increase Budget';
      } else if (this.increaseDays && this.increaseDays > this.currentDays) {
        text = 'Increase Days';
      } else {
        text = 'Add Product';
      }
      return text;
    },
  },
});
