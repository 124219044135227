
import { mapGetters, mapState } from 'vuex';
import ProductOptionContainer from './ProductOptionContainer.vue';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import Vue from 'vue';
import SemKeyword from '@/components/Proposal/Options/ProposalKeyword.vue';
import FlightsWrapper from '@/components/Proposal/Options/FlightsWrapper.vue';
import XmlProductOption from './Options/XMLProductOption.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { ConfiguredProduct, FlightConfig, UnsafeAny } from '@/shared/types';
import {
  BaseProductModelContract,
  ProposalProductModelContract,
  StatusModelContract,
  ValidationServiceContract,
} from '@/injectables';

import KeyMetrics from '@/components/ProductElement/KeyMetrics.vue';
import ProductInfo from '@/components/ProductElement/ProductInfo.vue';
import ProductStatusContainer from '@/components/ProductElement/ProductStatusContainer.vue';
import SpendConfigurationSkeleton from '@/components/ProductElement/SpendConfiguration/SpendConfigurationSkeleton.vue';
import LockBudgetButton from '@/components/ProductElement/SpendConfiguration/LockBudgetButton.vue';
import BudgetEditor from '@/components/ProductElement/SpendConfiguration/BudgetEditor.vue';
import { Models, Services } from '@/injectables/tokens';
import { ProductConfigCategory } from '@/app/graphql';

export default Vue.extend({
  name: 'ProposalProduct',

  inject: ['$confirm'],

  useInjectable: [Models.Package, Models.BaseProduct, Models.ProposalProduct],

  props: {
    proposalStatus: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    isSkeleton: {
      type: Boolean,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    proposalDuration: {
      type: Number,
      default: 1,
    },
  },

  components: {
    ProductInfoDialog,
    WrapperWithTooltip,
    ProductOptionContainer,
    SemKeyword,
    FlightsWrapper,
    XmlProductOption,
    KeyMetrics,
    ProductStatusContainer,
    ProductInfo,
    SpendConfigurationSkeleton,
    LockBudgetButton,
    BudgetEditor,
  },

  data: (): {
    editBudget: boolean;
    selectProductName: boolean;
    productSearchText: string;
    enableFlightsConfig: boolean;
    localTextFieldBudget: number;
    localBudget: number;
    unallocatedBudget: number;
    isUsingSlider: boolean;
    productSelection: UnsafeAny;
  } => ({
    productSelection: { name: '' },
    isUsingSlider: false,
    unallocatedBudget: 0,
    localTextFieldBudget: 0,
    localBudget: 0,
    editBudget: false,
    enableFlightsConfig: false,
    productSearchText: '',
    selectProductName: false,
  }),

  watch: {
    budget(val: number): void {
      if (val !== this.localTextFieldBudget) {
        this.localTextFieldBudget = val;
      }
    },
    localTextFieldBudget(val: number): void {
      if (val !== this.localBudget) {
        this.localBudget = val;
      }
    },
    product(product): void {
      if (product.name) {
        this.selectProductName = false;
      }
    },
    editBudget(val: boolean) {
      if (val) {
        this.localTextFieldBudget = this.budget;
      }
    },
  },

  mounted(): void {
    if (!this.product?.name && !this.isSkeleton) {
      this.selectProductName = true;
    }

    if (this.budget) {
      this.localTextFieldBudget = this.budget;
    }

    const autocompleteInput = this.$refs?.productAuto?.$refs?.input;

    if (autocompleteInput) autocompleteInput.addEventListener('focus', this.onFocus, true);
  },

  methods: {
    cancelConfig(): void {
      this.$emit('cancel-config');
    },
    productIsDisabled(productCandidate): boolean {
      const minSpend =
        productCandidate.category === ProductConfigCategory.Package
          ? productCandidate.budget
          : productCandidate.calcMinSpend;

      return !(
        this.xmlProductsOnly ||
        (this[Models.BaseProduct] as BaseProductModelContract).canAddNewProduct({
          minSpend,
          products: this.selectedProducts,
          productBudget: this.budget,
          solutionBudget: this.$store.state.newProposal.newProposal.budget,
        })
      );
    },
    productIsDisabledByDate(productCandidate): boolean {
      const { minDays = 0 } = productCandidate;

      if (!minDays) {
        return false;
      }

      const { startDate, endDate } = this.$store.state['newProposal'].newProposal;

      const dateRange = `${new Date(startDate)} - ${new Date(endDate)}`;

      const validation = this.rules.minRange(dateRange, minDays);

      return !!validation?.length;
    },
    packageIsBlocked(productCandidate): boolean {
      return this.packageEntity.isPackageBlocked(
        productCandidate,
        this.$store.state['newProposal'].newProposal.products,
      );
    },
    updateBudgetFromSlider(val: number) {
      this.isUsingSlider = false;

      this.updateBudget(val);
    },
    // used through ref
    openFlightConfig(): void {
      this.enableFlightsConfig = true;
    },
    // used through ref
    toggleBudgetLock(): void {
      this.budgetLock = !this.budgetLock;
    },
    // used through ref
    resetProduct(): void {
      this.$store.dispatch('newProposal/resetProductSettings', { productId: this.product.id }).catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    },
    newProductBudget(calcMinSpend: number, existingBudget: number): number {
      const budgetObj = {
        min: calcMinSpend,
        allocated: existingBudget,
      };
      return (this[Models.BaseProduct] as BaseProductModelContract).newProductBudget(
        budgetObj,
        this.$store.state['newProposal'].newProposal,
      );
    },
    openSelectProduct(): void {
      this.$refs.productAuto.isMenuActive = false;

      this.$emit('toggle-select-products');
    },
    closeSelectProduct(): void {
      this.selectProductName = false;

      this.$refs.productAuto.isMenuActive = false;
    },
    onFocus(): void {
      this.$refs.productAuto.isMenuActive = true;
    },
    closeBudgetEditor(): void {
      this.editBudget = false;

      this.localTextFieldBudget = this.budget;
    },
    manualBudgetUpdate(): void {
      if (this.localTextFieldBudget > this.productBudgetMax) {
        const newBudget = this.productBudgetMax;

        this.localTextFieldBudget = newBudget;

        this.updateBudget(newBudget);

        this.triggerSnackbar({ color: 'warning', message: `Product budget cannot exceed ${newBudget}` });
      } else if (this.localTextFieldBudget < this.productBudgetMin) {
        const newBudget = this.productBudgetMin;

        this.updateBudget(newBudget);

        this.localTextFieldBudget = newBudget;

        this.triggerSnackbar({ color: 'warning', message: `Product budget cannot be less than ${newBudget}` });
      } else {
        this.updateBudget(this.localTextFieldBudget);
      }
    },
    triggerSnackbar({ message, color }): void {
      this.$store.dispatch('showSnackbar', {
        content: message,
        color,
      });
    },
    async updateBudget(val: number, attempts = 4): Promise<void> {
      const statusEntity: StatusModelContract = this.$container.get(Models.Status);
      const { triggerRevertWarningStatuses } = statusEntity;

      if (triggerRevertWarningStatuses.includes(this.proposalStatus)) {
        this.localTextFieldBudget = val;
        const { confirmed } = await this.$confirm.show({
          title: 'Saving Changes will revert proposal back to In-Progress. ',
          body: 'Are you sure you want to continue',
          confirmText: 'Save',
          cancelText: 'Close',
        });
        if (!confirmed) {
          this.localTextFieldBudget = this.budget;
          this.localBudget = this.budget;
          return;
        }
      }

      this.awaitBudgetCheckLoading = true;

      if (attempts < 1) {
        this.localTextFieldBudget = this.budget;

        this.editBudget = false;

        this.awaitBudgetCheckLoading = false;

        this.triggerSnackbar({ color: 'warning', message: `Unable to update budget, please refresh` });
        return;
      }

      const recheckBudget = (budget): number => {
        let returnedBudget = budget;

        if (this.newProposalBudgetUnallocated !== 0) {
          const firstStep = Math.max(budget, this.budget) + this.newProposalBudgetUnallocated;

          returnedBudget = Math.min(firstStep, this.productBudgetMax);

          this.localTextFieldBudget = returnedBudget;

          this.triggerSnackbar({
            message: `Product budget cannot be less than ${this.$options.filters.formatPrice(returnedBudget)}`,
            color: 'warning',
          });
        }

        return returnedBudget;
      };

      if (val !== this.budget || this.newProposalBudgetUnallocated) {
        const validatedBudget = recheckBudget(val);

        const updatedBudget = validatedBudget;

        this.$store
          .dispatch('newProposal/updateProductBudget', { budget: updatedBudget, productId: this.product.id })
          .then(updatedBudget => this.updateBudget(updatedBudget, attempts - 1))
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('proposalProduct', 'updateBudget', err);

            this.localBudget = this.budget;

            this.triggerSnackbar({ color: 'error', message: `Unable to set this budget. Check console.` });

            this.awaitBudgetCheckLoading = false;
          });
      } else {
        this.awaitBudgetCheckLoading = false;
      }

      this.editBudget = false;
    },
    // TODO: set with budget 0 if no available budget, toggle inactive product state until sufficient allocatable budget;
    selectProduct(productSelection): void {
      if (
        productSelection?.name &&
        productSelection?.id &&
        productSelection?.category &&
        (productSelection.hasOwnProperty('minSpend') ||
          (this.isPackage && productSelection.hasOwnProperty('budget'))) &&
        !this.productIsDisabled(productSelection) &&
        !this.productIsDisabledByDate(productSelection) &&
        !this.packageIsBlocked(productSelection) &&
        (!this.isPackage || productSelection?.products)
      ) {
        this.$emit('add-product', {
          id: productSelection.id,
          xmlProductsOnly:
            this.xmlProductsOnly &&
            (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(productSelection),
        });
      } else {
        this.productSelection = { name: '' };
      }
    },
    getReadableCategory(categoryName?: string): string {
      let category = this.product.category;

      if (categoryName) {
        category = categoryName;
      }

      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
    productIcon(categoryName?: string): string {
      let category = this.product.category;

      if (categoryName) {
        category = categoryName;
      }

      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).icon;
    },
    productIconColor(categoryName?: string): string {
      let category = this.product.category;

      if (categoryName) {
        category = categoryName;
      }

      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).icon;
    },
    productFilter(item, queryText): boolean {
      const textOne = item.name.toLowerCase();

      const textTwo = item.category.toLowerCase();

      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitResetProduct(): void {
      this.$emit('reset-product', this.product);
    },
    productIsConfigured(product): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productIsConfigured(product);
    },
    xmlConfiguredProduct(product) {
      return (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(product);
    },
    smartMailerHasFlights(product) {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).smartMailerHasFlights(product);
    },
  },

  computed: {
    ...mapGetters('newProposal', {
      newProposalBudgetUnallocated: 'newProposalBudgetUnallocated',
      proposalBudgetMax: 'proposalBudgetMax',
    }),
    ...mapGetters('client', { activeClient: 'activeClient' }),
    ...mapState({ baseMarket: 'newProposal/newProposal.market' }),
    canResetProduct(): boolean {
      return !this.isChangeDisabled && !!this.product?.id && !this.XmlWithoutUpload;
    },
    documentLink() {
      return this.product?.broadcastInfo?.link;
    },
    BroadcastInfoList() {
      return this.product?.broadcastInfo?.broadcast;
    },
    flightConfigs(): FlightConfig[] {
      return this.product.flightConfigs;
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      return {
        minRange: validationService.minRangeValidatorFactory(),
      };
    },
    XmlWithoutUpload(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).noXmlConfiguredProduct(this.product);
    },
    lockedButtonText(): string {
      if (this.xmlConfiguredProduct(this.product) && !this.XmlWithoutUpload) {
        return 'XML-based Budget';
      }

      if (this.budgetIsDisabled) {
        return 'Spend locked';
      } else {
        return 'Spend unlocked';
      }
    },
    xmlProductsOnly(): boolean {
      if (!Array.isArray(this.selectedProducts)) {
        return false;
      }

      const filteredProducts = this.selectedProducts.filter(p => p?.name && p?.id);

      if (!filteredProducts.length) return false;
      return filteredProducts.every(
        p =>
          (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(p) ||
          (this[Models.BaseProduct] as BaseProductModelContract).noXmlConfiguredProduct(p),
      );
    },
    budgetTooltipText(): string {
      let text = 'Click to adjust product budget';

      if (this.xmlConfiguredProduct(this.product)) {
        text = 'Budget is already set from attached document';
      } else if (this.minAndMaxAreEqual) {
        text = 'Unable to change this budget unless proposal budget is first increased';
      } else if (this.saveProposalLoading) {
        text = 'Please wait for until for the update to finish loading';
      } else if (this.smartMailerHasFlights(this.product)) {
        text = 'Budget slider is disabled for for this product';
      } else if (this.budgetLock) {
        text = 'Unlock this product to edit the budget';
      }

      return text;
    },
    sliderIsDisabled(): boolean {
      return this.isChangeDisabled || this.saveProposalLoading || this.budgetIsDisabled;
    },
    budgetIsDisabled(): boolean {
      return (
        this.isChangeDisabled ||
        this.budgetLock ||
        this.smartMailerHasFlights(this.product) ||
        (this.xmlConfiguredProduct(this.product) && !this.XmlWithoutUpload)
      );
    },
    awaitBudgetCheckLoading: {
      get(): boolean {
        return this.$store.state.newProposal.awaitBudgetCheckLoading;
      },
      set(status: boolean): void {
        this.$store.dispatch('newProposal/setAwaitBudgetCheckLoading', status);
      },
    },
    saveProposalLoading(): boolean {
      return this.loading || this.awaitBudgetCheckLoading;
    },
    loadingRecommendations(): boolean {
      return (
        this.$store.state['newProposal'].updatingToIncreasedRecommendationsLoading ||
        this.$store.state['newProposal'].recommendationsLoading
      );
    },
    productClass(): string {
      return this.isNewProduct ? 'new-product' : this.invalid ? 'proposal-product warning-product' : 'proposal-product';
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state['newProposal'].newProposal.products;
    },
    selectedProposalId(): string {
      return this.$store.state['newProposal'].newProposal.id;
    },
    productHasBeenSelected(): boolean {
      return !!this.product?.name;
    },
    settingDotColor(): string {
      let color = 'grey lighten-2';

      if (this.product?.name && this.productIsConfigured(this.product)) {
        color = 'success';
      } else if (this.product?.name) {
        color = 'warning';
      } // else if (errorStatus) color = 'error'?

      return color;
    },
    minAndMaxAreEqual(): boolean {
      return this.productBudgetMax === this.productBudgetMin;
    },
    productBudgetMin(): number {
      const lockedFlightsSum = (this.product?.flights || [])
        .filter(fl => fl.isLocked)
        .reduce((acc, flight) => acc + flight.budget, 0);
      return Math.max(this.product.calcMinSpend, lockedFlightsSum);
    },
    productBudgetMax(): number {
      return (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
        this.product.id,
        this.$store.state.newProposal.newProposal.budget,
        this.selectedProducts,
      );
    },
    budgetLock: {
      get(): boolean {
        return this.product.isLocked;
      },
      set(val: boolean): void {
        this.$store.dispatch('newProposal/toggleProductLocked', { status: val, productId: this.product.id });
      },
    },
    budget(): number {
      return this.product?.budget;
    },
    isNewProduct(): boolean {
      return this.product?.isPlaceholder;
    },
    isXMLProduct(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).isXMLProduct(this.product);
    },
    isPaidSearch(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).isPaidSearchProduct(this.product);
    },
    keyMetricName(): string {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productKeyMetric(this.product);
    },
    productKpi() {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productKPI(this.product);
    },
    isPackage() {
      return this.productSelection?.category === ProductConfigCategory.Package;
    },
  },
});
