
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { ConfiguredProduct, ConfiguredFlight, UnsafeAny } from '@/shared/types';

import { Services, Models } from '@/injectables/tokens';
import {
  StatusModelContract,
  PackageProductModelContract,
  ProposalProductModelContract,
  DateModelContract,
} from '@/injectables';

import Product from '@/components/Proposal/ProposalProduct.vue';
import PackageProduct from '@/components/Proposal/PackageProduct.vue';
import ProductSelectDialog from '@/components/Proposal/ProductSelectDialog.vue';
import RecommendedProduct from '@/components/Proposal/RecommendedProduct.vue';
import { ProductConfigCategory } from '@/app/graphql';
import { MutationTypes as NewProposalMutationTypes } from '@/store/newProposal/mutations';
import { OldButtonVariants } from '@/shared/ui-kit/OldButton.vue';

export default Vue.extend({
  name: 'ProposalSolutions',

  inject: ['$confirm'],

  useInjectable: [Services.Tracker, Models.Package, Models.PackageProduct, Models.ProposalProduct, Models.Date],

  components: { Product, ProductSelectDialog, RecommendedProduct, PackageProduct },

  props: {
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    enableSelectProduct: boolean;
    proposalWillBeUpdated: boolean;
    selectedProductid: string | null;
    currentSelectedProductsState: ConfiguredProduct[] | null;
    showWizardProgress: boolean;
    timer: number | null;
  } => ({
    currentSelectedProductsState: null,
    selectedProductid: null,
    enableSelectProduct: false,
    proposalWillBeUpdated: false,
    showWizardProgress: false,
    timer: null,
  }),

  watch: {
    proposal: {
      handler(val, oldVal) {
        if (val.id && val.id !== oldVal.id) this.fetchProductRecommendations();
      },
      deep: true,
    },
  },

  computed: {
    OldButtonVariants() {
      return OldButtonVariants;
    },
    proposalDuration() {
      if (!this.proposal?.campaignEndDate || !this.proposal?.campaignStartDate) return 1;

      return (this[Models.Date] as DateModelContract).getFullMonthDuration(
        new Date(this.proposal?.campaignStartDate),
        new Date(this.proposal?.campaignEndDate),
      );
    },
    proposal() {
      return this.$store.state.newProposal.newProposal || {};
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    proposalLoading(): boolean {
      return this.$store.state.newProposal.saveProgressLoading || this.$store.state.newProposal.inProgressLoading;
    },
    selectedPackage() {
      return this.$store.state['newProposal'].newProposal.products.find(
        p => p.category === ProductConfigCategory.Package,
      );
    },
    availableForAddingProducts(): { category: string; name: string }[] {
      const availableProducts = this.productListWithoutUnconfiguredPackages.filter(product => {
        if (this.isPackage(product.category) && typeof this.selectedPackage === 'undefined') return true;
        if (this.isPackage(product.category) && typeof this.selectedPackage !== 'undefined') return false;

        return (
          !this.selectedPackage?.products?.map(p => p.productConfigId).includes(product.id) && // product and no packages with this product
          !this.packageEntity.isPackageBlocked(product, this.$store.state['newProposal'].newProposal.products) &&
          !this.$store.state['newProposal'].newProposal.products.some(p => p.productConfigId === product.id)
        );
      });
      return availableProducts;
    },
    productListWithoutUnconfiguredPackages() {
      const products = this.$store.state.newProposal.productConfigs?.list || [];
      return products
        .filter(product => {
          if (product.isHidden) return false;
          if (!this.isPackage(product.category)) return true;
          if (!Array.isArray(product.products)) return false;
          if (product.products.length === 0) return false;

          const packageProducts = product.products;
          return packageProducts.every(packageProduct =>
            (this[Models.PackageProduct] as PackageProductModelContract).productIsConfigured(packageProduct),
          );
        })
        .map(product => {
          if (this.isPackage(product.category)) return product;
          const calcMinSpend = (this[Models.ProposalProduct] as ProposalProductModelContract).getCalcMinSpend(
            product,
            this.proposalDuration,
          );

          return {
            ...product,
            calcMinSpend,
          };
        });
    },
    selectedProductIndex(): number | null {
      const index = this.$refs.products?.findIndex(el => el?.product?.id === this?.selectedProductid);
      if (isNaN(index)) return -1;
      return index;
    },
    proposalStatus(): { status: string; icon: string } | null {
      return this.$store.state.newProposal.newProposal.status;
    },
    canAddProduct(): boolean {
      return !this.isChangeDisabled && this.$store.getters['newProposal/canAddNewProduct'];
    },
    loadingRecommendations(): boolean {
      return (
        this.$store.state['newProposal'].updatingToIncreasedRecommendationsLoading ||
        this.$store.state['newProposal'].recommendationsLoading
      );
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state['newProposal'].newProposal?.products || [];
    },
    increasedRecommendations(): ConfiguredProduct[] {
      const products = this.$store.state.newProposal.newProposal?.increasedRecommendations?.ProductConfigList || [];
      const filtered = products.filter(product => !this.selectedProducts.some(p => p.id === product?.id));
      return filtered;
    },
    showElsyButton() {
      const isElsyProduct = this.selectedProducts.some(product => product.fromElsy);
      return this.isElsyEnabled && (isElsyProduct || !this.selectedProducts.length);
    },
    isElsyEnabled() {
      return this.$store.state['newProposal'].newProposal?.elsyEnabled;
    },
    budget() {
      return this.$store.state['newProposal'].newProposal?.budget;
    },
    elsyId() {
      return this.$store.state['newProposal'].newProposal?.ElsyId || '';
    },
  },

  methods: {
    ...mapMutations('newProposal', {
      // setProducts: NewProposalMutationTypes.SET_PRODUCTS,
      setXMLMutation: NewProposalMutationTypes.SET_XML,
      setNoXMLMutation: NewProposalMutationTypes.SET_NO_XML,
      // setProposalBudget: NewProposalMutationTypes.SET_NEW_PROPOSAL_BUDGET,
      toggleProductLocked: NewProposalMutationTypes.TOGGLE_PRODUCT_LOCKED,
      updateProductBudget: NewProposalMutationTypes.UPDATE_PRODUCT_BUDGET,
      // setFlightsToProduct: NewProposalMutationTypes.SET_PRODUCT_FLIGHTS,
    }),
    clearProducts(): void {
      this.$store.dispatch('newProposal/updateProducts', { products: [] });
    },
    isPackage(category): boolean {
      return ProductConfigCategory.Package === category;
    },
    setXml(productId, data) {
      this.setXMLMutation({ productId, ...data });
      this.updateProductBudget({ _budget: data.totalSpend, _productId: productId });
      this.toggleProductLocked({ _status: true, _productId: productId });
      this.$store.dispatch('newProposal/updateProducts', { products: [...this.selectedProducts] });
    },
    setNoXml(productId) {
      this.setNoXMLMutation(productId);
      this.toggleProductLocked({ _status: true, _productId: productId });
      this.$store.dispatch('newProposal/updateProducts', { products: [...this.selectedProducts] });
    },
    deleteXml(productId) {
      this.setXMLMutation({ productId, broadcast: null, link: null });
      this.toggleProductLocked({ _status: false, _productId: productId });
      this.$store.dispatch('newProposal/updateProducts', { products: [...this.selectedProducts] });
    },
    async setFlightGeoSelections(geoSelectionsObj: {
      target: string;
      targetArray: UnsafeAny[];
      productId: string;
      flightId: string;
    }): Promise<void> {
      await this.$store.dispatch('newProposal/setGeoSelections', geoSelectionsObj);
      this.$store.dispatch('newProposal/updateProducts', { products: [...this.selectedProducts], bypassRecalc: true });
    },
    addFlight({ productId }: { productId: string }): void {
      this.$store.dispatch('newProposal/addNewFlight', productId);
    },
    updateFlight(flightObj: {
      flight: ConfiguredFlight;
      flightId: string;
      sendUpdate?: boolean;
      recalculateProducts?: boolean;
    }): void {
      this.$store.dispatch('newProposal/updateExistingFlight', flightObj);
    },
    removeFlight({ flightId, productId }: { flightId: string; productId: string }): void {
      this.$store.dispatch('newProposal/removeFlight', { flightId, productId });
    },
    removeProductFromSolutions({ id }) {
      const updatedSelected = this.selectedProducts.filter(product => product.id !== id);
      this.$store.dispatch('newProposal/updateProducts', { products: updatedSelected });
    },
    addProductToSolution({ id, xmlProductsOnly }) {
      if (!this.selectedProducts.some(product => product.id === id)) {
        const productSelection = this.$store.state.newProposal.productConfigs.list.find(product => product.id === id);
        const maxIndex = (this[Models.ProposalProduct] as ProposalProductModelContract).getLastIndex(
          this.selectedProducts,
        );
        const newProduct = (this[Models.ProposalProduct] as ProposalProductModelContract).getProductFromConfig(
          productSelection,
          {
            clientGeoSelections: this.$store.state.client.activeClient.geoSelections,
            clientLocations: this.$store.state.client.activeClient.address,
            proposalGeoSelections: this.$store.state.newProposal.newProposal.market.geoSelections,
            startDate: this.$store.state.newProposal.newProposal.startDate,
            endDate: this.$store.state.newProposal.newProposal.endDate,
          },
        );
        const updatedSelected = [...this.selectedProducts];
        const isFlightedProduct = !!newProduct?.flights;

        const last = updatedSelected[updatedSelected.length - 1];

        if (last && last.isPlaceholder) {
          const updatedProduct = {
            ...newProduct,
            index: last.index,
          };
          updatedSelected[updatedSelected.length - 1] = updatedProduct;
        } else {
          const updatedProduct = {
            ...newProduct,
            index: maxIndex + 1,
          };
          updatedSelected.push(updatedProduct);
        }

        this.$store
          .dispatch('newProposal/updateProducts', {
            products: updatedSelected,
            updateProposalBudget: xmlProductsOnly,
          })
          .then(() => (isFlightedProduct ? this.$store.dispatch('newProposal/addNewFlight', newProduct.id) : {}))
          .then(() => this.$store.dispatch('newProposal/removeProductIncreasedRecommendations'))
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
      }
    },
    cancelConfig(): void {
      const productsToBeUpdate = this.currentSelectedProductsState.map(({ flights = null, ...p }) => {
        const correspondingProduct = this.selectedProducts.find(product => product.id === p.id);
        let freshFlights = null;
        if (correspondingProduct?.flights) freshFlights = [...correspondingProduct.flights];
        const flightsNotChanged =
          Array.isArray(freshFlights) &&
          Array.isArray(flights) &&
          JSON.stringify(flights) === JSON.stringify(freshFlights);
        return {
          ...p,
          // give all products isChanged flag to prevent BE recalc
          isChanged: true,
          // prevent recalc of flights if initial state has not been modified
          ...(flights && flightsNotChanged
            ? { flights: flights.map(f => ({ ...f, isChanged: true })) }
            : flights
            ? { flights }
            : {}),
        };
      });
      this.$store.dispatch('newProposal/updateProducts', {
        products: productsToBeUpdate,
      });
    },
    checkProposalStatusProductBudgetLock(product): void {
      if (product?.id) {
        this.selectedProductid = product.id;
        this.checkProposalStatusBeforeAction(null, 'updateBudgetLock');
      }
    },
    checkProposalStatusForProductOptions(product): void {
      if (product?.id) {
        this.selectedProductid = product.id;
        if (this.isChangeDisabled) {
          this.openProductOptions();
        } else {
          this.checkProposalStatusBeforeAction(null, 'openProductOptions');
        }
      }
    },
    checkProposalStatusForProductReset(product): void {
      if (product?.id) {
        this.selectedProductid = product.id;
        this.checkProposalStatusBeforeAction(null, 'resetProductOptions');
      }
    },
    async checkProposalStatusBeforeAction(option: string | number | null, action: string): Promise<void> {
      const statusEntity: StatusModelContract = this.$container.get(Models.Status);
      const { triggerRevertWarningStatuses } = statusEntity;

      if (triggerRevertWarningStatuses.includes(this.proposalStatus)) {
        const text = ['addProduct', 'openProductOptions'].includes(action) ? 'continue' : 'save';
        const { confirmed } = await this.$confirm.show({
          title: 'Saving Changes will revert proposal back to In-Progress. ',
          body: `Are you sure you want to ${text}?`,
          confirmText: 'Save',
          cancelText: 'Close',
        });
        if (!confirmed) {
          return;
        }
      }

      this.switchProductActions(action, option);
    },
    switchProductActions(
      action: 'openProductOptions' | 'deleteProduct' | 'addProduct' | 'updateBudgetLock' | 'resetProductOptions',
      option: string | number | null,
    ): void {
      switch (action) {
        case 'openProductOptions':
          this.openProductOptions();
          break;
        case 'deleteProduct':
          this.deleteProduct(option);
          break;
        case 'addProduct':
          this.addProduct();
          break;
        case 'updateBudgetLock':
          this.updateBudgetLock();
          break;
        case 'resetProductOptions':
          this.resetProductOptions();
          break;
        default:
          break;
      }
    },
    updateBudgetLock(): void {
      if (this.selectedProductIndex !== -1) {
        this.$refs.products[this.selectedProductIndex]?.toggleBudgetLock();
      } else {
        this.$store.dispatch('showSnackbar', {
          content: 'Unable to toggle budget lock at this time, please refresh.',
          color: 'error',
        });
      }
    },
    resetProductOptions(): void {
      if (this.selectedProductIndex !== -1) {
        this.$refs.products[this.selectedProductIndex]?.resetProduct();
      } else {
        this.$store.dispatch('showSnackbar', {
          content: 'Unable to reset product options at this time, please refresh.',
          color: 'error',
        });
      }
    },
    openProductOptions(): void {
      // copy values without reactivity
      this.currentSelectedProductsState = JSON.parse(JSON.stringify(this.selectedProducts));
      if (this.selectedProductIndex !== -1) {
        this.$refs.products[this.selectedProductIndex]?.openFlightConfig();
      } else {
        this.$store.dispatch('showSnackbar', {
          content: 'Unable to open product options at this time, please refresh.',
          color: 'error',
        });
      }
    },
    fetchProductRecommendations(): void {
      this.$store.dispatch('newProposal/fetchProductRecommendations');
    },
    addProduct(): void {
      const selected = [...this.selectedProducts];
      const maxIndex = Math.max(selected.length, Math.max(...selected.filter(p => p.index).map(p => p.index)));
      const newProduct = {
        index: maxIndex + 1,
        budget: 0,
        minSpend: 0,
        isLocked: false,
        isChanged: true,
        isPlaceholder: true,
      };
      selected.push(newProduct);
      this.$store.dispatch('newProposal/updateProducts', { products: selected, onlyLocalUpdate: true });
    },
    deleteProduct(productId: string): void {
      const selected = [...this.selectedProducts];
      const foundProduct = this.selectedProducts.find(p => p.id === productId);
      const wasEmpty = foundProduct?.budget === 0;
      const updatedSelected = selected.filter(product => product.id !== productId);
      this.$store.dispatch('newProposal/updateProducts', { products: updatedSelected, onlyLocalUpdate: wasEmpty });
    },
  },

  beforeRouteLeave(to, from, next): void {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },

  mounted(): void {
    if (!this.isElsyEnabled) {
      this.fetchProductRecommendations();
    }
  },
});
