
import Vue from 'vue';

import { Services, Models } from '@/injectables/tokens';
import { GeoModelContract } from '@/injectables';

import DateRangePickerWithQuickPeriods from '@/components/Proposal/DatePickerWithQuickPeriods.vue';
import EditableGeo from '@/widgets/instant-io/ui/geoselections-card/editable-card.vue';
import ProductSemKeyword from './SemKeyword.vue';
import { mapActions } from 'vuex';
import { UnsafeAny } from '@/shared/legacy/classes';
import { pickBy } from 'lodash';

const geoArrayNameMap = {
  DMA: 'dmaList',
  COUNTY: 'countyList',
  STATE: 'stateList',
  CITY: 'cityList',
  ZIP: 'zipList',
};

export default Vue.extend({
  name: 'SemKeywordsStandalone',

  useInjectable: [Models.Geo],

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ProductSemKeyword,
    EditableGeo,
    DateRangePickerWithQuickPeriods,
  },

  data: (): {
    currentGeoType: string;
    maxGeos: number;
  } => ({
    maxGeos: 100,
    currentGeoType: 'DMA',
  }),

  computed: {
    dates() {
      const { startDate, endDate } = this.$store.state.newProposal.newProposal;
      return [startDate, endDate];
    },
    clientUrl() {
      return this.$store.state.client?.activeClient?.url || '';
    },
    geoSelections() {
      const { cityList, countyList, dmaList, stateList } = this.mapSelect;

      return [...cityList, ...countyList, ...dmaList, ...stateList];
    },
    mapSelect() {
      const {
        stateList = [],
        dmaList = [],
        cityList = [],
        countyList = [],
      } = pickBy(this.product?.geoSelections || {}, Boolean);
      return { stateList, dmaList, cityList, countyList };
    },
    geoIsEmpty(): boolean {
      return this.geoSelections.length === 0;
    },
    productBudget() {
      return this.product.budget || 0;
    },
  },

  methods: {
    ...mapActions('product', { clearKeywords: 'clearKeywords' }),
    cleanGeo(geoName): string {
      return (this[Models.Geo] as GeoModelContract).cleanGeoString(geoName);
    },
    removeGeo(item): void {
      const updatedMapData = { ...this.mapSelect };

      const itemName = (this[Models.Geo] as GeoModelContract).cleanGeoString(item);

      Object.keys(updatedMapData).forEach((geoType: string) => {
        updatedMapData[geoType] = updatedMapData[geoType].filter(
          geo => (this[Models.Geo] as GeoModelContract).cleanGeoString(geo) !== itemName,
        );
      });

      this.setProductMarket(updatedMapData);
    },
    addGeo(geoObj: UnsafeAny): void {
      const newGeo = { ...this.mapSelect };

      if (newGeo[geoArrayNameMap[this.currentGeoType]]) newGeo[geoArrayNameMap[this.currentGeoType]].push(geoObj);
      else newGeo[geoArrayNameMap[this.currentGeoType]] = [geoObj];

      this.setProductMarket(newGeo);
    },
    setProductMarket(geoSelections) {
      this.$store.dispatch('newProposal/resetKeywords', this.product.id);
      this.$store.dispatch('newProposal/setProductGeo', { geoSelections, productId: this.product.id });
    },
    changeGeoType(geoType: string): void {
      this.currentGeoType = geoType;
    },
  },

  beforeDestroy() {
    this.clearKeywords();
  },
});
