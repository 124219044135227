
import { mapGetters, mapState } from 'vuex';
import ProductOptionContainer from './ProductOptionContainer.vue';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import Vue from 'vue';
import SemKeyword from '@/components/Proposal/Options/SemKeyword.vue';
import FlightsWrapper from '@/components/Proposal/Options/FlightsWrapper.vue';
import XmlProductOption from './Options/XMLProductOption.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProductStatusContainer from '@/components/ProductElement/ProductStatusContainer.vue';
import KeyMetrics from '@/components/ProductElement/KeyMetrics.vue';
import ProductInfo from '@/components/ProductElement/ProductInfo.vue';
import { ConfiguredProduct, ProductConfig, FlightConfig, UnsafeAny } from '@/shared/types';
import { Models } from '@/injectables/tokens';
import { BaseProductModelContract, ProposalProductModelContract } from '@/injectables';

export default Vue.extend({
  name: 'ProductFromPackage',

  useInjectable: [Models.BaseProduct, Models.ProposalProduct],

  props: {
    product: {
      type: Object,
    },
    package: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    isSkeleton: {
      type: Boolean,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ProductInfoDialog,
    ProductOptionContainer,
    SemKeyword,
    FlightsWrapper,
    XmlProductOption,
    WrapperWithTooltip,
    ProductStatusContainer,
    KeyMetrics,
    ProductInfo,
  },

  data: (): {
    editBudget: boolean;
    selectProductName: boolean;
    productSearchText: string;
    enableFlightsConfig: boolean;
    localTextFieldBudget: number;
    localBudget: number;
    unallocatedBudget: number;
    isUsingSlider: boolean;
    productSelection: UnsafeAny;
  } => ({
    productSelection: { name: '' },
    isUsingSlider: false,
    unallocatedBudget: 0,
    localTextFieldBudget: 0,
    localBudget: 0,
    editBudget: false,
    enableFlightsConfig: false,
    productSearchText: '',
    selectProductName: false,
  }),

  watch: {
    budget(val: number): void {
      if (val !== this.localTextFieldBudget) {
        this.localTextFieldBudget = val;
      }
    },
    localTextFieldBudget(val: number): void {
      if (val !== this.localBudget) {
        this.localBudget = val;
      }
    },
    product(product): void {
      if (product.name) {
        this.selectProductName = false;
      }
    },
    editBudget(val: boolean) {
      if (val) {
        this.localTextFieldBudget = this.budget;
      }
    },
  },

  mounted(): void {
    if (!this.product?.name && !this.isSkeleton) {
      this.selectProductName = true;
    }

    if (this.budget) {
      this.localTextFieldBudget = this.budget;
    }

    const autocompleteInput = this.$refs?.productAuto?.$refs?.input;

    if (autocompleteInput) autocompleteInput.addEventListener('focus', this.onFocus, true);
  },

  methods: {
    openConfig() {
      this.enableFlightsConfig = true;
    },
    cancelConfig(): void {
      this.enableFlightsConfig = false;
    },
    productIsDisabled(minSpend: number): boolean {
      return !(this[Models.ProposalProduct] as ProposalProductModelContract).canAddNewProduct({
        minSpend,
        productBudget: this.budget,
        solutionBudget: this.$store.state['newProposal'].newProposal.budget,
        products: this.$store.state['newProposal'].newProposal.products,
      });
    },
    updateBudgetFromSlider(val: number) {
      this.isUsingSlider = false;

      this.updateBudget(val);
    },

    // used through ref
    openFlightConfig(): void {
      this.enableFlightsConfig = true;
    },
    // used through ref
    toggleBudgetLock(): void {
      this.budgetLock = !this.budgetLock;
    },
    // used through ref
    resetProduct(): void {
      this.$store.dispatch('newProposal/resetProductSettings', { productId: this.product.PropertyId }).catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    },
    newProductBudget(minSpend: number, existingBudget: number): number {
      const budgetObj = {
        min: minSpend,
        allocated: existingBudget,
      };
      return (this[Models.BaseProduct] as BaseProductModelContract).newProductBudget(
        budgetObj,
        this.$store.state['newProposal'].newProposal,
      );
    },
    openSelectProduct(): void {
      this.$refs.productAuto.isMenuActive = false;

      this.$emit('toggle-select-products');
    },
    closeSelectProduct(): void {
      this.selectProductName = false;

      this.$refs.productAuto.isMenuActive = false;
    },
    onFocus(): void {
      this.$refs.productAuto.isMenuActive = true;
    },
    closeBudgetEditor(): void {
      this.editBudget = false;

      this.localTextFieldBudget = this.budget;
    },
    manualBudgetUpdate(): void {
      if (this.localTextFieldBudget > this.productBudgetMax) {
        const newBudget = this.productBudgetMax;

        this.localTextFieldBudget = newBudget;

        this.updateBudget(newBudget);

        this.triggerSnackbar({ color: 'warning', message: `Product budget cannot exceed ${newBudget}` });
      } else if (this.localTextFieldBudget < this.productBudgetMin) {
        const newBudget = this.productBudgetMin;

        this.updateBudget(newBudget);

        this.localTextFieldBudget = newBudget;

        this.triggerSnackbar({ color: 'warning', message: `Product budget cannot be less than ${newBudget}` });
      } else {
        this.updateBudget(this.localTextFieldBudget);
      }
    },
    triggerSnackbar({ message, color }): void {
      this.$store.dispatch('showSnackbar', {
        content: message,
        color,
      });
    },
    async updateBudget(val: number, attempts = 4): Promise<void> {
      this.awaitBudgetCheckLoading = true;

      if (attempts < 1) {
        this.localTextFieldBudget = this.budget;

        this.editBudget = false;

        this.awaitBudgetCheckLoading = false;

        this.triggerSnackbar({ color: 'warning', message: `Unable to update budget, please refresh` });
        return;
      }

      const recheckBudget = (budget): number => {
        let returnedBudget = budget;

        if (this.newProposalBudgetUnallocated !== 0) {
          const firstStep = Math.max(budget, this.budget) + this.newProposalBudgetUnallocated;

          returnedBudget = Math.min(firstStep, this.productBudgetMax(this.product.PropertyId));

          this.localTextFieldBudget = returnedBudget;

          this.triggerSnackbar({
            message: `Product budget cannot be less than ${this.$options.filters.formatPrice(returnedBudget)}`,
            color: 'warning',
          });
        }
        return returnedBudget;
      };

      if (val !== this.budget || this.newProposalBudgetUnallocated) {
        const validatedBudget = recheckBudget(val);

        const updatedBudget = validatedBudget;

        this.$store
          .dispatch('newProposal/updateProductBudget', { budget: updatedBudget, productId: this.product.PropertyId })
          .then(updatedBudget => this.updateBudget(updatedBudget, attempts - 1))
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('proposalProduct', 'updateProduct', err);

            this.localBudget = this.budget;

            this.triggerSnackbar({ color: 'error', message: `Unable to set this budget. Check console.` });

            this.awaitBudgetCheckLoading = false;
          });
      } else {
        this.awaitBudgetCheckLoading = false;
      }

      this.editBudget = false;
    },
    // TODO: set with budget 0 if no available budget, toggle inactive product state until sufficient allocatable budget;
    updateProduct(): void {
      if (
        this.productSelection?.name &&
        this.productSelection?.PropertyId &&
        this.productSelection?.category &&
        this.productSelection.hasOwnProperty('minSpend') &&
        !this.productIsDisabled(this.productSelection.minSpend)
      ) {
        const configDefaults = selection => {
          let propertyToReturn = {};

          if (selection?.OverallConfig?.uploadType === 'XML') {
            propertyToReturn = { broadcastInfo: [] };
          } else if (selection?.OverallConfig?.specialOption === 'SEMKeywordPlanner') {
            propertyToReturn = { Overall: { keywords: { list: [], summary: {}, keymetrics: '' } } };
          } else {
            propertyToReturn = { flights: [] };
          }

          return propertyToReturn;
        };

        const updatedProduct = {
          ...this.product,
          name: this.productSelection.name,
          isLocked: false,
          category: this.productSelection.category,
          minSpend: this.productSelection.minSpend,
          PropertyId: this.productSelection.PropertyId,
          ...(this.productSelection.minDays ? { minDays: this.productSelection.minDays } : {}),
          ...configDefaults(this.productSelection),
        };

        const selected = [...this.$store.state['newProposal'].newProposal.products];

        const productIndex = selected.findIndex(product => product.id && product.id === updatedProduct.id);

        if (productIndex !== -1) {
          const existingBudget = selected[productIndex].budget;

          updatedProduct.budget = this.newProductBudget(this.productSelection.minSpend, existingBudget);

          selected[productIndex] = updatedProduct;

          const isFlightedProduct = !!updatedProduct?.flights;

          this.$store
            .dispatch('newProposal/updateProducts', { products: selected })
            .then(() =>
              isFlightedProduct ? this.$store.dispatch('newProposal/addNewFlight', updatedProduct.PropertyId) : {},
            )
            .then(() => {
              this.selectProductName = false;

              this.localTextFieldBudget = updatedProduct.budget;
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error('ProposalProduct', 'updateProduct', err);
            });
        }
      } else {
        this.productSelection = { name: '' };
      }
    },
    categoryIconAndColor(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category);
    },
    productIcon(categoryName?: string): string {
      let category = this.product.category;

      if (categoryName) {
        category = categoryName;
      }

      return this.categoryIconAndColor(category).icon;
    },
    productIconColor(categoryName?: string): string {
      let category = this.product.category;

      if (categoryName) {
        category = categoryName;
      }

      return this.categoryIconAndColor(category).color;
    },
    productFilter(item, queryText): boolean {
      const textOne = item.name.toLowerCase();

      const textTwo = item.category.toLowerCase();

      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    productKpi(product): string {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productKPI(product);
    },
    productKeyMetric(product): string {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productKeyMetric(product);
    },
    xmlConfiguredProduct(product): boolean {
      return (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(product);
    },
    smartMailerHasFlights(product): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).smartMailerHasFlights(product);
    },
    productIsConfigured(product): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productIsConfigured(product);
    },
  },
  computed: {
    ...mapState({ baseMarket: 'newProposal/newProposal.market' }),
    ...mapGetters('newProposal', {
      newProposalBudgetUnallocated: 'newProposalBudgetUnallocated',
      proposalBudgetMax: 'proposalBudgetMax',
    }),
    productBudgetMax(): number {
      return (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
        this.product.PropertyId,
        this.$store.state.newProposal.newProposal.budget,
        this.$store.state.newProposal.newProposal.products,
      );
    },
    flightConfigs(): FlightConfig[] {
      return this.product?.flightConfigs || [];
    },
    budgetTooltipText(): string {
      let text = 'Click to adjust product budget';

      if (this.xmlConfiguredProduct(this.product)) {
        text = 'Budget is already set from attached document';
      } else if (this.minAndMaxAreEqual) {
        text = 'Unable to change this budget unless proposal budget is first increased';
      } else if (this.saveProposalLoading) {
        text = 'Please wait for until for the update to finish loading';
      } else if (this.smartMailerHasFlights(this.product)) {
        text = 'Budget slider is disabled for for this product';
      } else if (this.budgetLock) {
        text = 'Unlock this product to edit the budget';
      }

      return text;
    },
    sliderIsDisabled(): boolean {
      return this.saveProposalLoading || this.budgetIsDisabled;
    },
    budgetIsDisabled(): boolean {
      return this.budgetLock || this.smartMailerHasFlights(this.product) || this.xmlConfiguredProduct(this.product);
    },
    awaitBudgetCheckLoading: {
      get(): boolean {
        return this.$store.state.newProposal.awaitBudgetCheckLoading;
      },
      set(status: boolean): void {
        this.$store.dispatch('newProposal/setAwaitBudgetCheckLoading', status);
      },
    },
    saveProposalLoading(): boolean {
      return this.$store.state.newProposal.saveProgressLoading || this.awaitBudgetCheckLoading;
    },
    loadingRecommendations(): boolean {
      return (
        this.$store.state['newProposal'].updatingToIncreasedRecommendationsLoading ||
        this.$store.state['newProposal'].recommendationsLoading
      );
    },
    productClass(): string {
      return this.isNewProduct ? 'new-product' : this.invalid ? 'proposal-product warning-product' : 'proposal-product';
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state['newProposal'].newProposal.products;
    },
    selectedProposalId(): string {
      return this.$store.state['newProposal'].newProposal.id;
    },
    productHasBeenSelected(): boolean {
      return !!this.product?.name;
    },
    settingDotColor(): string {
      let color = 'grey lighten-2';

      if (this.product?.name && (this.isNoXML || this.productIsConfigured(this.product))) {
        color = 'success';
      } else if (this.product?.name) {
        color = 'warning';
      } // else if (errorStatus) color = 'error'?

      return color;
    },
    minAndMaxAreEqual(): boolean {
      return this.productBudgetMax === this.productBudgetMin;
    },
    productBudgetMin(): number {
      return this.product.minSpend;
    },
    budgetLock: {
      get(): boolean {
        return this.product.isLocked;
      },
      set(val: boolean): void {
        this.$store.dispatch('newProposal/toggleProductLocked', { status: val, productId: this.product.PropertyId });
      },
    },
    budget(): number {
      return this.product?.budget;
    },
    products(): { category: string; name: string }[] {
      const products = this.$store.state.newProposal.productConfigs?.list || [];

      return products.filter(product => {
        return !this.$store.state['newProposal'].newProposal.products.some(p => p.PropertyId === product.PropertyId);
      });
    },
    isNewProduct(): boolean {
      return !this.product?.name?.length;
    },
    isXMLProduct(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).isXMLProduct(this.product);
    },
    isNoXML(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).noXmlConfiguredProduct(this.product);
    },
    isPaidSearch(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).isPaidSearchProduct(this.product);
    },
    documentLink() {
      return this.product?.broadcastInfo?.link;
    },
    BroadcastInfoList() {
      return this.product?.broadcastInfo?.broadcast;
    },
    keyMetricName(): string {
      return this.productKeyMetric(this.product);
    },
    isStatusLink(): boolean {
      return this.product && this.product.name && !this.invalid && !this.saveProposalLoading;
    },
  },
});
