
import { mapGetters } from 'vuex';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import Vue from 'vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import Product from './ProductFromPackage.vue';
import ProductStatusContainer from '@/components/ProductElement/ProductStatusContainer.vue';
import LockBudgetButton from '@/components/ProductElement/SpendConfiguration/LockBudgetButton.vue';
import ProductInfo from '@/components/ProductElement/ProductInfo.vue';
import BudgetEditor from '@/components/ProductElement/SpendConfiguration/BudgetEditor.vue';
import { ConfiguredProduct, UnsafeAny } from '@/shared/types';
import { BaseProductModelContract, ProposalProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import { ProductConfigCategory } from '@/app/graphql';

export default Vue.extend({
  name: 'PackageProduct',

  useInjectable: [Models.BaseProduct, Models.ProposalProduct],

  props: {
    product: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    isSkeleton: {
      type: Boolean,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductInfoDialog,
    WrapperWithTooltip,
    Product,
    ProductStatusContainer,
    LockBudgetButton,
    ProductInfo,
    BudgetEditor,
  },
  data: (): {
    editBudget: boolean;
    selectProductName: boolean;
    productSearchText: string;
    enableFlightsConfig: boolean;
    localTextFieldBudget: number;
    localBudget: number;
    unallocatedBudget: number;
    isUsingSlider: boolean;
    productSelection: UnsafeAny;
  } => ({
    productSelection: { name: '' },
    isUsingSlider: false,
    unallocatedBudget: 0,
    localTextFieldBudget: 0,
    localBudget: 0,
    editBudget: false,
    enableFlightsConfig: false,
    productSearchText: '',
    selectProductName: false,
  }),
  watch: {
    budget(val: number): void {
      if (val !== this.localTextFieldBudget) {
        this.localTextFieldBudget = val;
      }
    },
    localTextFieldBudget(val: number): void {
      if (val !== this.localBudget) {
        this.localBudget = val;
      }
    },
    product(product): void {
      if (product.name) {
        this.selectProductName = false;
      }
    },
    editBudget(val: boolean) {
      if (val) {
        this.localTextFieldBudget = this.budget;
      }
    },
  },
  mounted(): void {
    if (!this.product?.name && !this.isSkeleton) {
      this.selectProductName = true;
    }
    if (this.budget) {
      this.localTextFieldBudget = this.budget;
    }
    const autocompleteInput = this.$refs?.productAuto?.$refs?.input;
    if (autocompleteInput) autocompleteInput.addEventListener('focus', this.onFocus, true);
  },
  methods: {
    cancelConfig(): void {
      this.$emit('cancel-config');
    },
    productIsDisabled(minSpend: number): boolean {
      return !(this[Models.ProposalProduct] as ProposalProductModelContract).canAddNewProduct({
        minSpend,
        productBudget: this.budget,
        products: this.selectedProducts,
        solutionBudget: this.$store.state['newProposal'].newProposal.budget,
      });
    },
    updateBudgetFromSlider(val: number) {
      this.isUsingSlider = false;
      this.updateBudget(val);
    },
    // used through ref
    openFlightConfig(): void {
      this.enableFlightsConfig = true;
    },
    // used through ref
    toggleBudgetLock(): void {
      this.budgetLock = !this.budgetLock;
    },
    // used through ref
    resetProduct(product = this.product): void {
      this.$store.dispatch('newProposal/resetProductSettings', { productId: product.PropertyId }).catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    },
    newProductBudget(minSpend: number, existingBudget: number): number {
      const budgetObj = {
        min: minSpend,
        allocated: existingBudget,
      };
      return (this[Models.BaseProduct] as BaseProductModelContract).newProductBudget(
        budgetObj,
        this.$store.state['newProposal'].newProposal,
      );
    },
    openSelectProduct(): void {
      this.$refs.productAuto.isMenuActive = false;
      this.$emit('toggle-select-products');
    },
    closeSelectProduct(): void {
      this.selectProductName = false;
      this.$refs.productAuto.isMenuActive = false;
    },
    onFocus(): void {
      this.$refs.productAuto.isMenuActive = true;
    },
    closeBudgetEditor(): void {
      this.editBudget = false;
      this.localTextFieldBudget = this.budget;
    },
    manualBudgetUpdate(): void {
      if (this.localTextFieldBudget > this.productBudgetMax) {
        const newBudget = this.productBudgetMax;
        this.localTextFieldBudget = newBudget;
        this.updateBudget(newBudget);
        this.triggerSnackbar({ color: 'warning', message: `Product budget cannot exceed ${newBudget}` });
      } else if (this.localTextFieldBudget < this.productBudgetMin) {
        const newBudget = this.productBudgetMin;
        this.updateBudget(newBudget);
        this.localTextFieldBudget = newBudget;
        this.triggerSnackbar({ color: 'warning', message: `Product budget cannot be less than ${newBudget}` });
      } else {
        this.updateBudget(this.localTextFieldBudget);
      }
    },
    triggerSnackbar({ message, color }): void {
      this.$store.dispatch('showSnackbar', {
        content: message,
        color,
      });
    },
    async updateBudget(val: number, attempts = 4): Promise<void> {
      this.awaitBudgetCheckLoading = true;
      if (attempts < 1) {
        this.localTextFieldBudget = this.budget;
        this.editBudget = false;
        this.awaitBudgetCheckLoading = false;
        this.triggerSnackbar({ color: 'warning', message: `Unable to update budget, please refresh` });
        return;
      }
      const recheckBudget = (budget): number => {
        let returnedBudget = budget;
        if (this.newProposalBudgetUnallocated !== 0) {
          const firstStep = Math.max(budget, this.budget) + this.newProposalBudgetUnallocated;

          const maxBudget = (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
            this.product.PropertyId,
            this.$store.state.newProposal.newProposal.budget,
            this.$store.state.newProposal.newProposal.products,
          );

          returnedBudget = Math.min(firstStep, maxBudget);
          this.localTextFieldBudget = returnedBudget;
          this.triggerSnackbar({
            message: `Product budget cannot be less than ${this.$options.filters.formatPrice(returnedBudget)}`,
            color: 'warning',
          });
        }
        return returnedBudget;
      };
      if (val !== this.budget || this.newProposalBudgetUnallocated) {
        const validatedBudget = recheckBudget(val);
        const updatedBudget = validatedBudget;
        this.$store
          .dispatch('newProposal/updateProductBudget', { budget: updatedBudget, productId: this.product.PropertyId })
          .then(updatedBudget => this.updateBudget(updatedBudget, attempts - 1))
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('proposalProduct', 'updateProduct', err);
            this.localBudget = this.budget;
            this.triggerSnackbar({ color: 'error', message: `Unable to set this budget. Check console.` });
            this.awaitBudgetCheckLoading = false;
          });
      } else {
        this.awaitBudgetCheckLoading = false;
      }
      this.editBudget = false;
    },
    productIcon(categoryName?: string): string {
      let category = this.product.category;
      if (categoryName) {
        category = categoryName;
      }
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).icon;
    },
    productIconColor(categoryName?: string): string {
      let category = this.product.category;
      if (categoryName) {
        category = categoryName;
      }
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).color;
    },
    productFilter(item, queryText): boolean {
      const textOne = item.name.toLowerCase();
      const textTwo = item.category.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    resetProductInPackage(product): void {
      this.resetProduct(product);
    },
    productIsConfigured(product): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productIsConfigured(product);
    },
    xmlConfiguredProduct(product): boolean {
      return (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(product);
    },
    smartMailerHasFlights(product) {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).smartMailerHasFlights(product);
    },
  },
  computed: {
    ...mapGetters({
      newProposalBudgetUnallocated: 'newProposal/newProposalBudgetUnallocated',
    }),
    productsLength(): number {
      return this.product.products?.length || 0;
    },
    configuratedProducts() {
      return this.product?.products?.filter(product => this.productIsConfigured(product)) || [];
    },
    budgetTooltipText(): string {
      let text = 'Click to adjust product budget';
      if (this.xmlConfiguredProduct(this.product)) {
        text = 'Budget is already set from attached document';
      } else if (this.minAndMaxAreEqual) {
        text = 'Unable to change this budget unless proposal budget is first increased';
      } else if (this.saveProposalLoading) {
        text = 'Please wait for until for the update to finish loading';
      } else if (this.smartMailerHasFlights(this.product)) {
        text = 'Budget slider is disabled for for this product';
      } else if (this.budgetLock) {
        text = 'Unlock this product to edit the budget';
      }
      return text;
    },
    sliderIsDisabled(): boolean {
      return this.isChangeDisabled || this.saveProposalLoading || this.budgetIsDisabled;
    },
    budgetIsDisabled(): boolean {
      return this.isChangeDisabled || this.product.category === ProductConfigCategory.Package;
    },
    awaitBudgetCheckLoading: {
      get(): boolean {
        return this.$store.state.newProposal.awaitBudgetCheckLoading;
      },
      set(status: boolean): void {
        this.$store.dispatch('newProposal/setAwaitBudgetCheckLoading', status);
      },
    },
    saveProposalLoading(): boolean {
      return this.$store.state.newProposal.saveProgressLoading || this.awaitBudgetCheckLoading;
    },
    loadingRecommendations(): boolean {
      return (
        this.$store.state['newProposal'].updatingToIncreasedRecommendationsLoading ||
        this.$store.state['newProposal'].recommendationsLoading
      );
    },
    productClass(): string {
      return this.isNewProduct ? 'new-product' : this.invalid ? 'proposal-product warning-product' : 'proposal-product';
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state['newProposal'].newProposal.products;
    },
    selectedProposalId(): string {
      return this.$store.state['newProposal'].newProposal.id;
    },
    productHasBeenSelected(): boolean {
      return !!this.product?.name;
    },
    settingDotColor(): string {
      let color = 'grey lighten-2';
      if (this.product?.name && this.product.products.every(product => this.productIsConfigured(product))) {
        color = 'success';
      } else if (this.product?.name) {
        color = 'warning';
      } // else if (errorStatus) color = 'error'?
      return color;
    },
    minAndMaxAreEqual(): boolean {
      return this.productBudgetMax === this.productBudgetMin;
    },
    productBudgetMin(): number {
      return this.product.minSpend;
    },
    productBudgetMax(): number {
      return (
        (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
          this.product.id,
          this.$store.state.newProposal.newProposal.budget,
          this.$store.state.newProposal.newProposal.products,
        ) || 10000
      );
    },
    budgetLock: {
      get(): boolean {
        return this.product.isLocked;
      },
      set(val: boolean): void {
        this.$store.dispatch('newProposal/toggleProductLocked', { status: val, productId: this.product.PropertyId });
      },
    },
    budget(): number {
      return this.product?.budget;
    },
  },
});
