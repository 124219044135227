
import Vue from 'vue';

export default Vue.extend({
  name: 'KeyMetrics',
  props: {
    isSkeleton: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
  },
});
